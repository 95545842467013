<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--SCRIPT TIMEOUT-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.lw_script_timeout") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="number"
          min="0"
          step="1"
          :placeholder="$t('admin_panel.lw_script_timeout_placeholder')"
          v-model.number="$data[moduleFields.TIMEOUT]"
        />
      </div>
    </div>

    <!--FULL SCREEN-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.FULL_SCREEN_ENABLED]">{{ $t("admin_panel.lw_full_screen_enabled") }} </b-switch>
    </b-field>

    <!--TABS ENABLED-->
    <b-field class="mt-1">
      <b-switch v-model="$data[moduleFields.TABS_ENABLED]">{{ $t("admin_panel.lw_tabs_enabled") }}</b-switch>
    </b-field>

    <!--LW CONTAINER ID-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.lw_container_id") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('admin_panel.lw_container_id')"
          v-model="$data[moduleFields.LW_CONTAINER_ID]"
        />
      </div>
    </div>

    <!--TABS-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.lw_tabs_configuration") }}</label>
      <live-webinar-tabs-builder
        class="mt-1"
        v-model="$data[moduleFields.TABS_DATA]"
        :tabs-enabled="$data[moduleFields.TABS_ENABLED]"
      ></live-webinar-tabs-builder>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LiveWebinarTabsBuilder from "@/web/components/admin/subpanels/modules/views/LiveWebinarTabsBuilder";

export default {
  name: "AdminModuleConfigLiveWebinar",
  components: { LiveWebinarTabsBuilder },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.LIVEWEBINAR_MODULE_FIELDS;
    return {
      [moduleFields.FULL_SCREEN_ENABLED]: this.value[moduleFields.FULL_SCREEN_ENABLED],
      [moduleFields.TABS_ENABLED]: this.value[moduleFields.TABS_ENABLED],
      [moduleFields.TABS_DATA]: this.value[moduleFields.TABS_DATA] || [],
      [moduleFields.LW_CONTAINER_ID]: this.value[moduleFields.LW_CONTAINER_ID] || "lw_container_id",
      [moduleFields.TIMEOUT]: this.value[moduleFields.TIMEOUT] || 0,
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.LIVEWEBINAR_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
