<template>
  <div class="sm-panel-container" :class="containerClasses">
    <div class="sm-panel-menu-container" v-prevent-parent-scroll>
      <menu-icon class="sm-panel-menu-icon"></menu-icon>
      <div class="sm-panel-menu-title" v-if="!!sideMenuPanel">{{ title }}</div>
      <button class="button is-primary sm-panel-menu-back-button" @click="goBack" v-if="!!sideMenuPanel">
        <back-icon class="sm-panel-menu-back-button-icon svg-icon"></back-icon>
      </button>
    </div>

    <template v-if="!!sideMenuPanel">
      <component
        :is="panelComponent(sideMenuPanel.type)"
        v-bind="panelBindings(sideMenuPanel)"
        ref="sideMenuPanel"
        :is-active="false"
        :is-panel="true"
      ></component>
    </template>

    <div class="sm-panel-buttons" v-else v-prevent-parent-scroll>
      <action-overlay-view class="sm-panel-button-container" top="-12%" left="-12%" v-if="sessionDiscussionVisible">
        <template>
          <b-tooltip :label="sessionDiscussionBubbleTooltip" :delay="500" position="is-left">
            <button class="button sm-panel-button unselectable" @click="openAgendaSessionQuestions">
              <img class="svg-icon" v-if="sessionDiscussionBubbleIcon" :src="sessionDiscussionBubbleIcon" />
              <agenda-session-questions-icon class="sm-button-icon svg-icon" v-else></agenda-session-questions-icon>
            </button>
          </b-tooltip>
        </template>
      </action-overlay-view>

      <action-overlay-view class="sm-panel-button-container" top="-12%" left="-12%" v-if="inboxVisible">
        <template>
          <b-tooltip :label="inboxBubbleTooltip" :delay="500" position="is-left">
            <button class="button sm-panel-button unselectable" @click="openInbox">
              <img class="svg-icon" v-if="inboxBubbleIcon" :src="inboxBubbleIcon" />
              <inbox-icon class="sm-button-icon svg-icon is-inbox" v-else></inbox-icon>
            </button>
          </b-tooltip>
        </template>

        <template #overlay>
          <div v-if="unreadMessagesCount" class="side-menu-unread-indicator is-small">
            <div class="side-menu-unread-text">{{ unreadMessagesCount > 9 ? "+" : unreadMessagesCount }}</div>
          </div>
        </template>
      </action-overlay-view>

      <action-overlay-view class="sm-panel-button-container" top="-12%" left="-12%" v-if="feedWallVisible">
        <template>
          <b-tooltip :label="feedWallBubbleTooltip" :delay="500" position="is-left">
            <button class="button sm-panel-button unselectable" @click="openFeedWall">
              <img class="svg-icon" v-if="feedWallBubbleIcon" :src="feedWallBubbleIcon" />
              <feed-wall-icon class="sm-button-icon svg-icon" v-else></feed-wall-icon>
            </button>
          </b-tooltip>
        </template>
      </action-overlay-view>

      <action-overlay-view class="sm-panel-button-container" top="-12%" left="-12%" v-if="quizVisible">
        <template>
          <b-tooltip :label="quizBubbleTooltip" :delay="500" position="is-left">
            <button class="button sm-panel-button unselectable" @click="openQuizzes">
              <img class="svg-icon" v-if="quizBubbleIcon" :src="quizBubbleIcon" />
              <quiz-icon class="sm-button-icon svg-icon" v-else></quiz-icon>
            </button>
          </b-tooltip>
        </template>

        <template #overlay>
          <div v-if="uncompletedQuizzesCount" class="side-menu-unread-indicator is-small">
            <div class="side-menu-unread-text">{{ uncompletedQuizzesCount > 9 ? "+" : uncompletedQuizzesCount }}</div>
          </div>
        </template>
      </action-overlay-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import Constants from "@/web/constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import QuizIcon from "@/assets/icons/menu-quiz.svg";
import InboxIcon from "@/assets/navbar/message-icon.svg";
import FeedWallIcon from "@/assets/icons/menu-feed-wall.svg";
import AgendaSessionQuestionsIcon from "@/assets/icons/rupor.svg";
import MenuIcon from "@/assets/icons/menu.svg";
import BackIcon from "@/assets/icon-back.svg";
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import InboxBubble from "@/web/components/sidemenu/bubbles/InboxBubble";
import QuizBubble from "@/web/components/sidemenu/bubbles/QuizBubble";
import FeedWallBubble from "@/web/components/sidemenu/bubbles/FeedWallBubble";
import AgendaSessionQuestionsBubble from "@/web/components/sidemenu/bubbles/AgendaSessionQuestionsBubble";

export default {
  name: "SideMenuPanel",
  mixins: [LpConfigMixin],
  components: {
    MenuIcon,
    BackIcon,
    QuizIcon,
    InboxIcon,
    FeedWallIcon,
    AgendaSessionQuestionsIcon,
    ActionOverlayView,
  },

  data() {
    return {
      scrollListener: null,
    };
  },

  beforeDestroy() {
    this.stopOnScrollListener();
  },

  methods: {
    ...mapMutations("sideMenu", ["setSideMenuPanel", "setSideMenuPanelSneakPeakMode"]),

    openQuizzes() {
      this.setSideMenuPanel({
        id: 0,
        type: Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE,
      });
    },

    openInbox() {
      this.setSideMenuPanel({
        id: 0,
        type: Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES,
      });
    },

    openAgendaSessionQuestions() {
      this.setSideMenuPanel({
        id: 0,
        type: Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS,
      });
    },

    openFeedWall() {
      this.setSideMenuPanel({
        id: 0,
        type: Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE,
      });
    },

    goBack() {
      if (this.$refs.sideMenuPanel && !this.$refs.sideMenuPanel.navigateBack()) {
        this.setSideMenuPanel(null);
      }
    },

    startOnScrollListener() {
      if (!this.scrollListener) {
        this.scrollListener = () => {
          this.setSideMenuPanelSneakPeakMode(false);
          this.stopOnScrollListener();
        };
      }
      document.addEventListener("scroll", this.scrollListener);
    },

    stopOnScrollListener() {
      if (this.scrollListener) {
        document.removeEventListener("scroll", this.scrollListener);
      }
    },

    getCustomBubbleTooltip(type) {
      return this.getCustomTranslation(this.sideMenuBubblesTooltips, type, null);
    },

    getCustomBubbleIcon(type) {
      return this.getCustomTranslation(this.sideMenuBubblesIcons, type, null);
    },

    panelComponent(type) {
      if (type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        return InboxBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) {
        return QuizBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        return FeedWallBubble;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        return AgendaSessionQuestionsBubble;
      } else {
        throw Error("Invalid side menu bubble type");
      }
    },

    panelBindings(panel) {
      const commonBindings = {
        bubble: panel,
      };
      if (panel.type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        return {
          ...commonBindings,
        };
      } else if (panel.type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) {
        return {
          ...commonBindings,
          quizModules: this.quizModules,
        };
      } else if (panel.type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        return {
          ...commonBindings,
        };
      } else if (panel.type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        return {
          ...commonBindings,
        };
      } else {
        throw Error("Invalid side menu bubble type");
      }
    },
  },

  computed: {
    ...mapGetters(["inboxEnabled", "quizModules", "feedWallModules"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTooltips", "sideMenuBubblesIcons", "isSideMenuPanelSneakPeakMode"]),
    ...mapState("sideMenu", ["sideMenuPanel"]),
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),
    ...mapGetters("inbox", ["unreadMessagesCount"]),
    ...mapGetters("quizzes", ["uncompletedQuizzesCount"]),
    ...mapGetters("agendaSessionQuestions", ["isSessionDiscussionEnabled"]),
    ...mapGetters("userTickets", ["ticket"]),

    title() {
      return "";
    },

    containerClasses() {
      return {
        "is-opened": !!this.sideMenuPanel,
        "is-sneak-peak": this.isSideMenuPanelSneakPeakMode,
      };
    },

    inboxVisible() {
      return this.inboxEnabled;
    },

    quizVisible() {
      return this.quizModules.length;
    },

    feedWallVisible() {
      return this.feedWallModules.length;
    },

    sessionDiscussionVisible() {
      return (this.eventId !== 1312 && this.isSessionDiscussionEnabled) || (this.eventId === 1312 && !!this.ticket);
    },

    inboxBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) || this.$t("inbox.inbox");
    },

    feedWallBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) || this.$t("feedwall.feedwall_title");
    },

    sessionDiscussionBubbleTooltip() {
      return (
        this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) ||
        this.$t("session_questions.session_questions_title")
      );
    },

    quizBubbleTooltip() {
      return this.getCustomBubbleTooltip(Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) || this.$t("quizzes.quizzes_and_surveys");
    },

    inboxBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES);
    },

    feedWallBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE);
    },

    sessionDiscussionBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS);
    },

    quizBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE);
    },
  },

  watch: {
    isSideMenuPanelSneakPeakMode: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.startOnScrollListener();
        }
      },
    },
  },
};
</script>

<style scoped></style>
