<template>
  <div class="mb-1">
    <label class="label mb-2"> {{ title }} </label>
    <div class="columns is-multiline mx-1">
      <div
        v-for="locale in supportedLocales"
        :key="locale.countryCode"
        @click="currentLocale = locale.localeCode"
        class="has-flag-button"
        :style="{ background: locale.localeCode === currentLocale ? '#BDBDBD' : '' }"
      >
        <country-flag :code="locale.countryCode"></country-flag>
      </div>
    </div>

    <div class="control mt-1">
      <input class="input" type="text" v-model="currentLocaleLabel" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CountryFlag from "@/web/components/shared/CountryFlag";

export default {
  name: "FieldLabelEditor",

  components: { CountryFlag },

  props: {
    title: {
      type: String,
      required: true,
    },

    currentLabelTranslations: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      labelTranslations: this.currentLabelTranslations || {},
      currentLocale: "en",
      currentLocaleLabel: "",
    };
  },

  computed: {
    ...mapGetters("locales", ["supportedLocales"]),
  },

  watch: {
    currentLocaleLabel(newValue) {
      this.labelTranslations[this.currentLocale] = newValue ? newValue : null;
      this.$emit("input", this.labelTranslations);
    },

    currentLocale: {
      immediate: true,
      handler: function (newValue) {
        this.currentLocaleLabel = this.labelTranslations[newValue] || "";
      },
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  color: var(--text-color-primary);
}

.has-flag-button {
  padding: 4px;
  cursor: pointer;
  border: #828282 1px solid;
  border-radius: 4px;
  margin: 2px;
  background-clip: border-box;

  &:hover {
    background: #c0c0c0;
  }

  img {
    display: block;
    width: 26px;
    height: auto;
  }
}
</style>
