export default {
  props: {
    bubble: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    bubbleId() {
      return this.bubble.id;
    },
  },

  methods: {
    setAsActiveBubble() {
      this.$emit("active", this.bubbleId);
    },

    setAsInActiveBubble() {
      this.$emit("inactive", this.bubbleId);
    },

    refreshLayout() {
      this.$refs.bubbleBox.refreshLayout();
    },
  },
};
