<template>
  <div class="has-text-centered p-1 has-fullheight is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <div class="title">{{ $t("quizzes.quiz_in_progress_prompt") }}</div>
    <div class="mt-2 is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
      <div class="button is-rounded is-danger mr-2" @click="$emit('result', false)">{{ $t("common.no_lowercase") }}</div>
      <div class="button is-rounded is-primary" @click="$emit('result', true)">{{ $t("common.yes_lowercase") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizInProgressPrompt",
};
</script>

<style scoped>
.title {
  font-size: 18px;
  line-height: 25px;
}
</style>
