<template>
  <!--MODULE SPECIFIC FIELDS-->
  <div class="mt-1">
    <!--Questions-->
    <div class="field">
      <label class="label has-text-primary">{{ $t("admin_panel.iframe_tabs_configuration") }}</label>
      <faq-questions-editor class="mt-1" v-model="$data[moduleFields.QUESTIONS_DATA]"></faq-questions-editor>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import FaqQuestionsEditor from "@/web/components/admin/subpanels/modules/views/FaqQuestionsEditor";

export default {
  name: "AdminModuleConfigFaq",
  components: { FaqQuestionsEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.FAQ_MODULE_FIELDS;
    return {
      [moduleFields.QUESTIONS_DATA]: (this.value[moduleFields.QUESTIONS_DATA] && [...this.value[moduleFields.QUESTIONS_DATA]]) || [],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.FAQ_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  methods: {
    getKeyTranslations(translationKey) {
      return Object.entries(this.translations).reduce((acc, keyValue) => ({ ...acc, [keyValue[0]]: keyValue[1][translationKey] }), {});
    },

    updateCurrentKeyTranslations(translations) {
      this.editModeUpdatedTranslations = translations;
      this.editModeTranslationsUpdateCount = this.editModeTranslationsUpdateCount + 1;
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
