import { render, staticRenderFns } from "./InboxBubbleContactList.vue?vue&type=template&id=16b2f436&scoped=true&"
import script from "./InboxBubbleContactList.vue?vue&type=script&lang=js&"
export * from "./InboxBubbleContactList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b2f436",
  null
  
)

export default component.exports