<template>
  <notifications group="lp-notifications" position="bottom right" width="400px">
    <template slot="body" slot-scope="props">
      <div class="notification" @click.prevent="onNotificationClick(props.item, props.close)">
        <div class="columns is-mobile is-gapless">
          <div class="column is-narrow">
            <div class="notification-icon-container">
              <profile-picture
                class="is-36x36"
                v-if="props.item.type === Constants.NOTIFICATION_TYPE.INBOX"
                :picture="props.item.data.message.user.picture"
                :initials="initials(props.item.data.message.user)"
              >
              </profile-picture>
              <div
                v-else-if="props.item.data.event && props.item.data.event.logo && props.item.type === Constants.NOTIFICATION_TYPE.EVENT"
                class="box is-inline-flex has-logo is-clipped"
              >
                <figure class="image is-centered is-36x36">
                  <img class="logo-image" :src="props.item.data.event.logo.file_thumbnail_200_url" />
                </figure>
              </div>
              <div v-else class="notification-icon-background">
                <component :is="notificationIcon(props.item.type)" class="has-fill-primary"></component>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="notification-title">
              {{ props.item.title }}
            </div>
            <div class="notification-subtitle">{{ props.item.text }}</div>
          </div>
          <div class="column is-narrow">
            <div class="close-button" @click.prevent.stop="props.close">
              <close-icon class="svg-icon"></close-icon>
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { fullName, initials } from "@/shared/utils";
import Constants from "@/web/constants";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import NotificationInfoIcon from "@/assets/notification/notification_info.svg";
import NotificationFriendIcon from "@/assets/notification/notification_friend.svg";
import NotificationMeetingIcon from "@/assets/notification/notification_meeting.svg";
import CloseIcon from "@/assets/icon_close.svg";
import {
  getComponentId,
  getExhibitorId,
  getFeedWallChannelId,
  getFeedWallThreadId,
  isBusinessMatchingComponent,
  hasBusinessMatchingAcceptedTab,
  hasBusinessMatchingPendingTab,
  hasBusinessMatchingMeetingVideoCallRoom,
  getBusinessMatchingMeetingId,
  getQuizId,
  isExhibitorComponent,
  isFeedWallComponent,
  isQuizzesComponent,
} from "@/shared/utils/deeplink-utils";
import ILoveMarketingRatingModal from "../custom/ILoveMarketingRatingModal";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "NotificationsHub",

  components: {
    ProfilePicture,
    CloseIcon,
  },

  computed: {
    ...mapState(["event", "eventId"]),
    ...mapGetters(["agendaRatingPopupConfig"]),
    ...mapState("notifications", ["friendNotification", "inboxThreadNotification", "standardNotification"]),
    ...mapGetters("inbox", ["unreadThreads"]),

    Constants: () => Constants,

    initials: () => initials,

    notificationSound() {
      return new Audio(require("../../../assets/sounds/notification.mp3"));
    },

    notificationDuration: () => 4200,
  },

  watch: {
    unreadThreads(newVal, oldVal) {
      this.updateTabTitle(newVal.length);
    },
    friendNotification(newVal, oldVal) {
      if (newVal) {
        this.showFriendInvitationNotification(newVal);
        this.playNotificationSound();
        this.clearFriendNotification();
      }
    },
    inboxThreadNotification(newVal, oldVal) {
      if (newVal) {
        this.showInboxNotification(newVal);
        this.playNotificationSound();
        this.clearInboxThreadNotification();
      }
    },

    standardNotification(newVal, oldVal) {
      if (newVal) {
        //CUSTOM CHANGE FOR ILOVEMARKETING
        if (this.eventId === this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.EVENT_ID]) {
          if (newVal.dl) {
            const deepLink = newVal.dl;
            if (isQuizzesComponent(deepLink)) {
              const componentId = getComponentId(deepLink);
              if (
                componentId === this.agendaRatingPopupConfig[LpConfigConstants.FEATURES_FIELDS.AGENDA_RATING_POPUP_FIELDS.QUIZ_COMPONENT_ID]
              ) {
                const quizId = getQuizId(deepLink);
                this.$buefy.modal.open({
                  parent: this,
                  component: ILoveMarketingRatingModal,
                  hasModalCard: true,
                  canCancel: true,
                  trapFocus: true,
                  props: {
                    componentId: componentId,
                    quizId: quizId,
                  },
                });
                this.playNotificationSound();
              } else {
                this.showStandardNotification(newVal);
                this.playNotificationSound();
                this.clearStandardNotification();
              }
            } else {
              this.showStandardNotification(newVal);
              this.playNotificationSound();
              this.clearStandardNotification();
            }
          } else {
            this.showStandardNotification(newVal);
            this.playNotificationSound();
            this.clearStandardNotification();
          }
        } else {
          this.showStandardNotification(newVal);
          this.playNotificationSound();
          this.clearStandardNotification();
        }
      }
    },
  },

  methods: {
    ...mapMutations("notifications", ["clearFriendNotification", "clearInboxThreadNotification", "clearStandardNotification"]),
    ...mapActions("notifications", ["markNotificationAsRead"]),
    ...mapActions("sideMenu", ["openFeedWallBubble", "openQuizBubble"]),

    notificationIcon(notificationType) {
      if (notificationType === Constants.NOTIFICATION_TYPE.FRIENDS) {
        return NotificationFriendIcon;
      } else if (notificationType === Constants.NOTIFICATION_TYPE.EVENT) {
        return NotificationInfoIcon;
      } else if (notificationType === Constants.NOTIFICATION_TYPE.BUSINESS_MATCHING) {
        return NotificationMeetingIcon;
      } else {
        return NotificationInfoIcon;
      }
    },

    playNotificationSound() {
      this.notificationSound.play().catch(err => {
        //no-op, user hasn't interacted with document yet
      });
    },

    updateTabTitle(notificationsCount) {
      if (notificationsCount) {
        document.title = `(${notificationsCount}) ${this.event.title}`;
      } else {
        document.title = this.event.title;
      }
    },

    showFriendInvitationNotification(friendInvitation) {
      this.$notify({
        group: "lp-notifications",
        title: this.$t("friends.invitation_notification_title"),
        text: this.$t("friends.invitation_notification_text", [fullName(friendInvitation)]),
        type: Constants.NOTIFICATION_TYPE.FRIENDS,
        data: friendInvitation,
        duration: this.notificationDuration,
      });
    },

    showInboxNotification(inboxNotification) {
      this.$notify({
        group: "lp-notifications",
        title: fullName(inboxNotification.message.user),
        text: inboxNotification.message.message,
        type: Constants.NOTIFICATION_TYPE.INBOX,
        data: inboxNotification,
        duration: this.notificationDuration,
      });
    },

    showStandardNotification(standardNotification) {
      this.$notify({
        group: "lp-notifications",
        title: standardNotification.title,
        text: standardNotification.message,
        type: standardNotification.notify_type,
        data: standardNotification,
        duration: this.notificationDuration,
      });
    },

    onNotificationClick(notification, onFinishAction) {
      this.markNotificationAsRead(notification.id);

      const notificationType = notification.type;
      const notificationSubtype = notification.data.notify_subtype;

      if (notificationType === Constants.NOTIFICATION_TYPE.FRIENDS) {
        this.$router.push({ name: Constants.ROUTE_FRIENDS_INVITATIONS });
      } else if (notificationType === Constants.NOTIFICATION_TYPE.BUSINESS_MATCHING) {
        if (!this.handleDeepLink(notification.data.dl)) {
          this.$root.openNotificationModal(notification.data);
        }
      } else if (notificationType === Constants.NOTIFICATION_TYPE.INBOX) {
        const threadId = notification.data.id;
        this.$router.push({ name: Constants.ROUTE_INBOX_THREAD, params: { threadId } });
      } else if (notificationType === Constants.NOTIFICATION_TYPE.EVENT) {
        if (notificationSubtype === Constants.NOTIFICATION_SUBTYPE.EVENT_STANDARD) {
          if (!this.handleDeepLink(notification.data.dl)) {
            this.$root.openNotificationModal(notification.data);
          }
        }
      } else if (notificationType === Constants.NOTIFICATION_TYPE.FEED_WALL) {
        if (!this.handleDeepLink(notification.data.dl)) {
          this.$root.openNotificationModal(notification.data);
        }
      } else {
        this.$root.openNotificationModal(notification.data);
      }
      onFinishAction();
    },

    handleDeepLink(deepLink) {
      if (deepLink) {
        if (isExhibitorComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const exhibitorId = getExhibitorId(deepLink);
          this.$router.push({ name: Constants.ROUTE_EXHIBITOR, params: { componentId, exhibitorId } });
          return true;
        } else if (isQuizzesComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const quizId = getQuizId(deepLink);
          // this.$router.push({name: Constants.ROUTE_QUIZ_QUESTIONS, params: {componentId, quizId}});
          this.openQuizBubble(quizId);
          return true;
        } else if (isFeedWallComponent(deepLink)) {
          const componentId = getComponentId(deepLink);
          const feedWallChannelId = getFeedWallChannelId(deepLink);
          const feedWallThreadId = getFeedWallThreadId(deepLink);
          this.openFeedWallBubble({ feedWallChannelId: feedWallChannelId, feedWallThreadId: feedWallThreadId });
          return true;
        } else if (isBusinessMatchingComponent(deepLink)) {
          if (hasBusinessMatchingMeetingVideoCallRoom(deepLink)) {
            this.openJitsiCallFromDeeplink(deepLink);
            return true;
          } else if (hasBusinessMatchingAcceptedTab(deepLink)) {
            const meetingId = getBusinessMatchingMeetingId(deepLink);
            this.$router.push({ name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED, params: { meetingId: meetingId } });
            return true;
          } else if (hasBusinessMatchingPendingTab(deepLink)) {
            const meetingId = getBusinessMatchingMeetingId(deepLink);
            this.$router.push({ name: Constants.ROUTE_BUSINESS_MATCHING_PENDING, params: { meetingId: meetingId } });
            return true;
          }
          return false;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.columns {
  width: 100%;
}
</style>
