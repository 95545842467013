<template>
  <bubble-box
    ref="bubbleBox"
    :is-panel="isPanel"
    :title="bubbleTitle"
    :is-full-height="isBubbleFullHeight"
    :back-button-visible="navigateBackEnabled"
    :add-button-visible="addButtonEnabled"
    :bubble-id="bubble.id"
    :background-color="backgroundColor"
    :content-padding="'0'"
    :v-chat-enabled="false"
    :is-initialized="true"
    :is-active="isActive"
    init-scroll-direction="top"
    @navigate-back="navigateBack"
    @minimize="$emit('minimize', getBubbleToMinimize())"
    @close="$emit('close', bubble)"
    @add="onAddButtonClick"
    @bottom-of-scroll="onBottomOfScroll"
    @active="setAsActiveBubble"
    @inactive="setAsInActiveBubble"
  >
    <template v-if="initialLoadingIndicatorVisible">
      <div class="is-relative" style="height: 310px" v-prevent-parent-scroll>
        <b-loading :is-full-page="false" :active="true"></b-loading>
      </div>
    </template>
    <template v-else-if="selectedThread">
      <feed-wall-thread :thread="selectedThread" :is-list-item="false" :is-small="true"></feed-wall-thread>
    </template>
    <template v-else-if="selectedChannelId" class="has-background-primary">
      <feed-wall-threads
        :channel-id="selectedChannelId"
        :is-small="true"
        :is-loading="areThreadsLoading"
        @open-thread="selectThread"
      ></feed-wall-threads>
    </template>
    <template v-else>
      <feed-wall-channels
        :channels="getAllChannels"
        @open-channel="selectChannel"
        :is-small="true"
        :is-loading="areChannelsLoading"
      ></feed-wall-channels>
    </template>

    <template #fixed-footer v-if="selectedThreadId">
      <inbox-text-input
        v-prevent-parent-scroll
        class="p-1"
        ref="inboxTextInput"
        :is-small="true"
        :is-requesting="isRequesting"
        v-model="messageText"
        v-on:send="sendComment"
        @refresh-layout="refreshLayout"
      ></inbox-text-input>
    </template>
    <template #fixed-footer v-else-if="isPanel && selectedChannelId">
      <div class="side-menu-panel-thread-add-button-container is-fixed-bottom is-fullwidth" v-prevent-parent-scroll>
        <button class="button is-primary side-menu-panel-thread-add-button" @click="onAddButtonClick">
          <add-icon class="side-menu-panel-thread-add-button-icon"></add-icon>
          {{ $t("feedwall.feedwall_add_post_title") }}
        </button>
      </div>
    </template>
    <template #fixed-footer v-else></template>
  </bubble-box>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Constants from "@/web/constants";
import BubbleBox from "@/web/components/sidemenu/BubbleBox";
import FeedWallChannels from "@/web/components/feedwall/FeedWallChannels";
import FeedWallThreads from "@/web/components/feedwall/FeedWallThreads";
import FeedWallThread from "@/web/components/feedwall/FeedWallThread";
import InboxTextInput from "@/web/components/inbox/InboxTextInput";
import FeedWallCreateThreadModal from "@/web/components/feedwall/FeedWallCreateThreadModal";
import RequestMixin from "@/shared/mixins/RequestMixin";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import AddIcon from "@/assets/icons/add.svg";

export default {
  name: "FeedWallBubble",

  components: { BubbleBox, FeedWallChannels, FeedWallThreads, FeedWallThread, InboxTextInput, AddIcon },

  mixins: [RequestMixin, LpConfigMixin],

  props: {
    bubble: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: true,
    },

    isPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initialDataLoading: true,
      selectedChannelId: null,
      selectedThreadId: null,
      messageText: "",
    };
  },

  mounted() {
    this.setAsActiveBubble();
    this.fetchData({
      selectedChannelId: this.selectedChannelId,
      selectedThreadId: this.selectedThreadId,
      isUpdate: !this.isFresh,
    })
      .catch(error => {
        if (error === Constants.FEED_WALL_CHANNEL_NOT_FOUND || error === Constants.FEED_WALL_THREAD_NOT_FOUND) {
          this.selectedThreadId = null;
          this.selectedChannelId = null;
        }
      })
      .finally(() => {
        this.initialDataLoading = false;
      });
  },

  computed: {
    ...mapGetters(["feedWallModules"]),
    ...mapGetters("feedWall", {
      getAllChannels: "getAllChannels",
      areChannelsLoading: "getIsAnyLoading",
    }),
    ...mapGetters("feedWallChannel", {
      getAreThreadsLoading: "getIsLoading",
      threadById: "threadById",
      getLastThreadTimestamp: "getLastThreadTimestamp",
    }),
    ...mapGetters("feedWallThread", ["getLastCommentTimestamp"]),
    ...mapState(["eventId"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesTitles"]),

    bubbleId() {
      return this.bubble.id;
    },

    bubbleTitle() {
      const bubbleType = LpConfigConstants.SIDE_MENU_FIELDS.BUBBLE_TYPES.FEED_WALL_BUBBLE;
      return this.getCustomTranslation(this.sideMenuBubblesTitles, bubbleType, this.$t("feedwall.feedwall"));
    },

    isFresh() {
      return !!this.bubble.isFresh;
    },

    selectedThread() {
      return this.threadById(this.selectedThreadId);
    },

    backgroundColor() {
      if (this.selectedChannelId) {
        return "var(--side-menu-feedwall-bg-color)";
      } else {
        return "var(--side-menu-feedwall-bg-secondary-color)";
      }
    },

    navigateBackEnabled() {
      return !!(this.selectedChannelId || this.selectedThreadId);
    },

    addButtonEnabled() {
      return !!(this.selectedChannelId && !this.selectedThreadId);
    },

    isBubbleFullHeight() {
      return !this.selectedThreadId;
    },

    areThreadsLoading() {
      return this.getAreThreadsLoading(this.selectedChannelId);
    },

    initialLoadingIndicatorVisible() {
      return this.initialDataLoading && this.isFresh && (this.selectedThreadId || this.selectedChannelId);
    },
  },

  methods: {
    ...mapActions("feedWall", ["loadChannels", "fetchData"]),
    ...mapActions("feedWallChannel", ["loadThreads", "updateThreads", "loadThread"]),
    ...mapActions("feedWallThread", ["loadComments", "updateComments", "postComment"]),

    getBubbleToMinimize() {
      return {
        id: this.bubble.id,
        type: this.bubble.type,
        feedWallChannelId: this.selectedChannelId,
        feedWallThreadId: this.selectedThreadId,
        savedScrollPosition: this.$refs.bubbleBox.getSavedScrollPosition(),
      };
    },

    selectChannel(channelId) {
      this.selectedChannelId = channelId;
      if (this.getLastThreadTimestamp(channelId)) {
        this.updateThreads({ channelId: this.selectedChannelId });
      } else {
        this.loadThreads({ channelId: this.selectedChannelId });
      }
    },

    selectThread(threadId) {
      this.$refs.bubbleBox.saveScrollPosition();
      this.selectedThreadId = threadId;
      this.loadThread({ channelId: this.selectedChannelId, threadId: this.selectedThreadId });
      if (this.getLastCommentTimestamp(threadId)) {
        this.updateComments({ channelId: this.selectedChannelId, threadId: this.selectedThreadId });
      } else {
        this.loadComments({ channelId: this.selectedChannelId, threadId: this.selectedThreadId });
      }
      this.$refs.bubbleBox.setInitScroll(true);
      this.refreshLayout();
    },

    navigateBack() {
      let navigated = false;
      if (this.selectedThreadId) {
        this.selectedThreadId = null;
        this.$refs.bubbleBox.restoreScrollPosition();
        if (!this.getLastThreadTimestamp(this.selectedChannelId)) {
          this.loadThreads({ channelId: this.selectedChannelId });
        }
        navigated = true;
      } else if (this.selectedChannelId) {
        this.selectedChannelId = null;
        navigated = true;
      }
      this.refreshLayout();
      return navigated;
    },

    onBottomOfScroll() {
      if (this.selectedThread) {
        this.loadComments({ channelId: this.selectedChannelId, threadId: this.selectedThreadId });
      } else if (this.selectedChannelId) {
        this.loadThreads({ channelId: this.selectedChannelId });
      }
    },

    onAddButtonClick() {
      this.openCreateThread();
    },

    openCreateThread() {
      this.$buefy.modal.open({
        parent: this,
        component: FeedWallCreateThreadModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: { channelId: this.selectedChannelId },
      });
    },

    setAsActiveBubble() {
      this.$emit("active", this.bubbleId);
    },

    setAsInActiveBubble() {
      this.$emit("inactive", this.bubbleId);
    },

    refreshLayout() {
      this.$refs.bubbleBox.refreshLayout();
    },
  },

  requests: {
    async sendComment(message) {
      await this.postComment({
        channelId: this.selectedChannelId,
        threadId: this.selectedThreadId,
        message: message,
      }).catch(err => {
        this.$refs.inboxTextInput.setText(message);
        throw err;
      });
    },
  },

  watch: {
    bubble: {
      immediate: true,
      handler: function (newValue) {
        if (newValue.feedWallThreadId) {
          this.selectedThreadId = newValue.feedWallThreadId;
        }
        if (newValue.feedWallChannelId) {
          this.selectedChannelId = newValue.feedWallChannelId;
        }
        if (newValue.savedScrollPosition) {
          this.$nextTick(() => {
            this.$refs.bubbleBox.setSavedScrollPosition(newValue.savedScrollPosition);
            if (newValue.feedWallChannelId && !newValue.feedWallThreadId) {
              this.$refs.bubbleBox.setInitScroll(false);
              this.$refs.bubbleBox.restoreScrollPosition();
            }
          });
        }
      },
    },

    getAllChannels: {
      immediate: true,
      handler: function (newValue) {
        //auto redirect to channel if there is only one available
        if (newValue && newValue.length === 1) {
          if (this.selectedChannelId === null) {
            this.selectChannel(newValue[0].id);
          }
        }
      },
    },

    error(newVal) {
      if (newVal) {
        if (newVal === Constants.FEED_WALL_CHANNEL_NOT_FOUND) {
          const err = this.$t("feedwall.feedwall_channel_not_found");
          this.$root.openErrorModal(err);
          this.selectedThreadId = null;
          this.selectedChannelId = null;
        } else {
          this.$root.openErrorModal(newVal);
        }
        this.error = null;
      }
    },
  },
};
</script>

<style scoped></style>
