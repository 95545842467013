<template>
  <b-rate
    v-model="rate"
    icon-pack="mdi"
    icon="star"
    :max="max"
    :size="size"
    :spaced="true"
    :show-score="false"
    :disabled="disabled"
    :show-text="false"
  >
  </b-rate>
</template>

<script>
export default {
  name: "StarsRating",

  props: {
    value: {
      type: Number,
    },

    disabled: {
      type: Boolean,
    },

    //acceptable values: is-small, is-medium, is-large
    size: {
      type: String,
      default: "is-small",
    },

    max: {
      type: Number,
      default: 5,
    },
  },

  data() {
    return {
      rate: 0,
    };
  },

  computed: {
    showValue() {
      return !!this.value;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.rate = newValue;
      },
    },

    rate: {
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
