<template>
  <!--  <div>-->
  <div v-if="questions.length">
    <agenda-bubble-question
      class="mb-1"
      v-for="question in questions"
      :key="question.id"
      :question="question"
      :component-id="componentId"
      :is-small="isSmall"
    ></agenda-bubble-question>
  </div>
  <div v-else class="placeholder-container has-text-secondary is-relative">
    <b-loading v-if="isLoading" :is-full-page="false" :active="true"></b-loading>
    <div v-else>
      {{ $t("session_questions.questions_empty_placeholder") }}
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AgendaBubbleQuestion from "@/web/components/agenda/questions/AgendaBubbleQuestion";

export default {
  name: "AgendaBubbleQuestionsList",

  components: { AgendaBubbleQuestion },

  props: {
    agendaSession: {
      type: Object,
      required: true,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("agendaSessionQuestions", ["getQuestionsBySessionId", "getIsLoading"]),

    componentId() {
      return this.agendaSession.event_component_id;
    },

    sessionId() {
      return this.agendaSession.id;
    },

    questions() {
      return this.getQuestionsBySessionId(this.sessionId);
    },

    isLoading() {
      return this.getIsLoading(this.sessionId);
    },
  },

  methods: {
    ...mapActions("agendaSessionQuestions", ["fetchQuestions"]),
  },

  mounted() {
    this.fetchQuestions({ componentId: this.componentId, agendaSessionId: this.sessionId });
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}
</style>
