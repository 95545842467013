<template>
  <div>
    <div class="box">
      <div v-for="agendaComponent in agendaComponents" :key="agendaComponent.id">
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <checkbox :value="value.includes(agendaComponent.id)" @input="toggleSelection($event, agendaComponent.id)"></checkbox>
          </div>
          <div class="column">
            <div>{{ agendaComponent.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Checkbox from "@/shared/components/form/Checkbox";

export default {
  name: "AgendaComponentIdsPicker",
  components: { Checkbox },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters("components", ["agendaComponents"]),
  },

  methods: {
    toggleSelection(isSelected, agendaComponentId) {
      if (isSelected) {
        this.$emit("input", [...this.value, agendaComponentId]);
      } else {
        this.$emit("input", [...this.value.filter(selectedId => selectedId !== agendaComponentId)]);
      }
    },
  },
};
</script>

<style scoped></style>
