<template>
  <action-overlay-view top="0%" right="0%" :key="bubble.id">
    <template>
      <action-overlay-view bottom="0%" right="0%">
        <template v-if="bubbleType === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES">
          <div class="side-menu-bubble-profile" @click="maximize" v-if="inboxThreadUser">
            <profile-picture
              :picture="inboxThreadUserAvatar"
              :initials="inboxThreadUserInitials"
              class="side-menu-bubble-picture"
            ></profile-picture>
          </div>
          <div class="side-menu-bubble side-menu-bubble-inbox is-small" @click="maximize" v-else>
            <img class="svg-icon" v-if="quizBubbleIcon" :src="inboxBubbleIcon" />
            <inbox-icon class="svg-icon has-fill-primary" v-else></inbox-icon>
          </div>
        </template>

        <template v-else-if="bubbleType === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE">
          <div class="side-menu-bubble is-small" @click="maximize">
            <img class="svg-icon" v-if="quizBubbleIcon" :src="quizBubbleIcon" />
            <quiz-icon class="svg-icon has-fill-primary" v-else></quiz-icon>
          </div>
        </template>

        <template v-else-if="bubbleType === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE">
          <div class="side-menu-bubble is-small" @click="maximize">
            <img class="svg-icon" v-if="quizBubbleIcon" :src="feedWallBubbleIcon" />
            <feed-wall-icon class="svg-icon has-fill-primary" v-else></feed-wall-icon>
          </div>
        </template>

        <template v-else-if="bubbleType === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS">
          <div class="side-menu-bubble is-small" @click="maximize">
            <img class="svg-icon" v-if="quizBubbleIcon" :src="sessionDiscussionBubbleIcon" />
            <agenda-session-questions-icon class="svg-icon has-fill-primary" v-else></agenda-session-questions-icon>
          </div>
        </template>

        <template #overlay>
          <div v-if="unreadIndicator" class="side-menu-unread-indicator is-small">
            <div v-if="unreadIndicatorCountVisible" class="side-menu-unread-text">{{ unreadIndicator }}</div>
          </div>
        </template>
      </action-overlay-view>
    </template>

    <template #overlay>
      <div class="close-button" @click="close">
        <close-icon class="svg-icon close-icon"></close-icon>
      </div>
    </template>
  </action-overlay-view>
</template>

<script>
import ActionOverlayView from "@/web/components/shared/ActionOverlayView";
import CloseIcon from "@/assets/icon_close.svg";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { initials } from "@/shared/utils";
import Constants from "@/web/constants";
import InboxIcon from "@/assets/navbar/message-icon.svg";
import QuizIcon from "@/assets/icons/menu-quiz.svg";
import FeedWallIcon from "@/assets/icons/menu-feed-wall.svg";
import AgendaSessionQuestionsIcon from "@/assets/icons/rupor.svg";
import { mapGetters } from "vuex";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "MinimizedBubble",
  mixins: [LpConfigMixin],
  components: {
    ProfilePicture,
    ActionOverlayView,
    CloseIcon,
    InboxIcon,
    QuizIcon,
    FeedWallIcon,
    AgendaSessionQuestionsIcon,
  },

  props: {
    bubble: {
      type: Object,
      required: true,
    },
    currentUserUuid: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters("inbox", ["unreadMessagesCount"]),
    ...mapGetters("quizzes", ["uncompletedQuizzesCount"]),
    ...mapGetters("inboxMessages", ["unreadMessagesFromThread"]),
    ...mapGetters("sideMenu", ["sideMenuBubblesIcons"]),

    //COMMON
    Constants: () => Constants,

    bubbleType() {
      return this.bubble.type;
    },

    unreadIndicator() {
      const type = this.bubbleType;
      if (type === Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES) {
        if (this.inboxThreadId) {
          const unreadMessagesFromThread = this.unreadMessagesFromThread(this.inboxThreadId, this.currentUserUuid);
          if (unreadMessagesFromThread > 9) {
            return "+";
          } else {
            return unreadMessagesFromThread;
          }
        } else {
          return this.unreadMessagesCount;
        }
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE) {
        if (this.quizId) {
          return 0;
        } else {
          return this.uncompletedQuizzesCount;
        }
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE) {
        return 0;
      } else if (type === Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS) {
        return 0;
      } else {
        throw Error("Invalid side menu bubble type");
      }
    },

    unreadIndicatorCountVisible() {
      return this.inboxThreadUser || (this.bubbleType === Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE && !this.quizId);
    },

    //INBOX BUBBLE
    inboxThreadUser() {
      return this.bubble.inboxThreadUser;
    },

    inboxThreadId() {
      return this.bubble.inboxThreadId;
    },

    inboxThreadUserInitials() {
      return initials(this.bubble.inboxThreadUser);
    },

    inboxThreadUserAvatar() {
      return this.inboxThreadUser && this.inboxThreadUser.picture;
    },

    //QUIZ BUBBLE
    quizId() {
      return this.bubble.quizId;
    },

    //ICONS

    inboxBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES);
    },

    feedWallBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE);
    },

    sessionDiscussionBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS);
    },

    quizBubbleIcon() {
      return this.getCustomBubbleIcon(Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE);
    },
  },

  methods: {
    close() {
      this.$emit("close", this.bubble);
    },

    maximize() {
      this.$emit("maximize", this.bubble);
    },

    getCustomBubbleIcon(type) {
      return this.getCustomTranslation(this.sideMenuBubblesIcons, type, null);
    },
  },
};
</script>

<style scoped lang="scss">
.close-button {
  width: 20px;
  height: 20px;
  padding: 4.5px;
  cursor: pointer;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.5);

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}

.close-icon {
  stroke: white;
}
</style>
