<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title px-2" v-if="quiz">
        {{ title }}
      </h1>
      <div class="modal-card-title is-size-5 px-2" v-if="quiz && !isCompleted && questionTitle">
        {{ questionTitle }}
      </div>
      <div class="close-button" @click="close">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <template v-if="quiz">
        <template v-if="isCompleted">
          <div class="quiz-your-score my-2">
            {{ $t("quizzes.survey_feedback_title") }}
          </div>
          <b-button class="button is-fullwidth is-primary mb-2 mt-2" @click="close">
            {{ $t("common.finish") }}
          </b-button>
        </template>
        <template v-else>
          <div class="is-flex is-flex-direction-row is-align-items-flex-end">
            <div class="mr-2">
              {{ $t("custom.ilovemarketing_bad") }}
            </div>
            <div>
              <stars-rating :disabled="false" :max="6" @input="setRating" size="is-large"></stars-rating>
              <div class="is-flex is-flex-direction-row">
                <div class="star-label">1</div>
                <div class="star-label">2</div>
                <div class="star-label">3</div>
                <div class="star-label">4</div>
                <div class="star-label">5</div>
                <div class="star-label">6</div>
              </div>
            </div>
            <div class="ml-2">
              {{ $t("custom.ilovemarketing_awesome") }}
            </div>
          </div>
          <div class="mt-2 has-fullwidth">
            <b-button class="button is-fullwidth is-primary mb-2 mt-2" @click="send" v-if="rating > 0">
              {{ $t("common.send") }}
            </b-button>
            <b-button class="button is-fullwidth is-primary mb-2 mt-2" disabled v-else>
              {{ $t("common.send") }}
            </b-button>
            <div v-if="error" class="has-text-danger has-text-centered">
              {{ error }}
            </div>
            <b-loading :active="isRequesting" :is-full-page="false"></b-loading>
          </div>
        </template>
      </template>
      <div v-else>
        <list-loading-indicator :active="true"></list-loading-indicator>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import StarsRating from "@/shared/components/common/rating/StarsRating";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "ILoveMarketingRatingModal",

  components: { ListLoadingIndicator, StarsRating, CloseIcon },

  props: {
    componentId: {
      type: Number,
      required: true,
    },

    quizId: {
      type: Number,
      required: true,
    },
  },

  mixins: [RequestMixin],

  beforeDestroy() {
    this.clearStandardNotification();
  },

  requests: {
    async sendRequest(credentials) {
      await this.submitILoveMarketingRating(credentials).then(response => {
        this.close();
      });
    },
  },

  data() {
    return {
      rating: 0,
      hasRequested: false,
    };
  },

  computed: {
    ...mapGetters("quizzes", ["getById", "isComponentLoading"]),
    ...mapState("notifications", ["standardNotification"]),

    quiz() {
      return this.getById(this.quizId);
    },

    title() {
      return this.quiz.name;
    },

    question() {
      return this.quiz.survey_quiz_questions[0];
    },

    questionId() {
      return this.question.id;
    },

    questionTitle() {
      if (this.question.question.replace(/^\s+|\s+$/g, "")) {
        return this.question.question;
      } else {
        return null;
      }
    },

    answer() {
      return this.question.survey_quiz_answers.find(answer => answer.answer.includes(this.rating.toString()));
    },

    isCompleted() {
      return this.quiz.is_completed_by_me;
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes", "submitILoveMarketingRating"]),
    ...mapMutations("notifications", ["clearStandardNotification"]),

    setRating(rating) {
      this.rating = rating;
    },

    send() {
      if (this.answer && this.question.mode === Constants.QUIZ_ANSWER_MODE.RADIO && this.question.survey_quiz_answers.length === 6) {
        const response = {
          question: this.questionId,
          answer: this.answer.id,
        };
        this.sendRequest({ componentId: this.componentId, quizId: this.quizId, response: response });
      } else {
        this.$root.openErrorModal(
          "Źle skonfigurowany quiz, powinien zawierać tylko i wyłącznie jedno pytanie typu zamkniętego z 6 odpowiedziami o treściach zawierających: '1', '2', '3', '4', '5', '6' Pozdrawiam cieplutko"
        );
      }
    },

    close() {
      this.clearStandardNotification();
      if (this.$parent) {
        this.$parent.close();
      }
    },
  },

  watch: {
    quiz: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          //do nothing
        } else if (!this.hasRequested) {
          this.hasRequested = true;
          this.loadQuizzes(this.componentId).then(result => {
            const quiz = result.response.data.find(quiz => quiz.id === this.quizId);
            if (!quiz) {
              this.close();
            }
          });
        }
      },
    },

    standardNotification: {
      immediate: false,
      handler: function (newValue) {
        if (newValue === null) {
          this.close();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
    width: 100%;
    height: 100%;
  }
}

.modal-card-title {
  word-break: break-word;
}

.modal-card-foot {
  //padding: 40px;
  padding-bottom: 10px;
}

.star-label {
  flex: 1 1;
  text-align: center;

  &:not(:last-child) {
    margin-right: 0.4rem;
  }

  &:not(:first-child) {
    margin-left: 0.4rem;
  }
}
</style>
