<template>
  <div class="box">
    <div class="is-flex is-flex-direction-row is-align-items-center is-justify-content-center mb-2">
      <div class="is-size-5 mr-1">
        {{ $t("admin_panel.timer_configuration_title") }}
      </div>
      <b-button type="is-primary" class="is-small" @click="save">{{ $t("admin_panel.save") }}</b-button>
    </div>

    <!--AUTO RESIZE-->
    <b-field>
      <b-switch v-model="hidden">{{ $t("admin_panel.timer_is_hidden") }}</b-switch>
    </b-field>

    <!--DATE-->
    <div class="field" v-if="!hidden">
      <label class="label has-text-primary">{{ $t("admin_panel.timer_countdown_date") }}</label>
      <b-datetimepicker
        v-model="date"
        rounded
        :placeholder="$t('admin_panel.click_to_select')"
        size="is-small"
        icon="calendar-today"
        :icon-right="date ? 'close-circle' : ''"
        icon-right-clickable
        @icon-right-click="clearDateTime"
        :locale="currentLocale.localeCode"
      >
      </b-datetimepicker>
    </div>

    <!--BOTTOM TEXT-->
    <div class="field" v-if="!hidden">
      <label class="label has-text-primary">{{ $t("admin_panel.timer_bottom_text") }}</label>
      <b-button type="is-primary" class="admin-translation-key-button" @click="editBottomText">
        {{ bottomTextTranslationKey }}
      </b-button>
    </div>

    <!--BOTTOM TEXT COLOR-->
    <div class="field" v-if="!hidden">
      <label class="label has-text-primary">{{ $t("admin_panel.timer_bottom_text_color") }}</label>
      <div class="control">
        <input class="input is-simple" type="color" :placeholder="$t('admin_panel.timer_bottom_text_color')" v-model="bottomTextColor" />
      </div>
    </div>

    <!--BOTTOM TEXT SIZE-->
    <div class="field" v-if="!hidden">
      <label class="label has-text-primary">{{ $t("admin_panel.timer_bottom_text_size") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('admin_panel.timer_bottom_text_size')" v-model="bottomTextSize" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminTimerConfiguration",

  data() {
    return {
      awaitingFieldForTranslationKey: null,
      //Timer fields
      hidden: false,
      date: null,
      bottomText: null,
      bottomTextColor: null,
      bottomTextSize: null,
    };
  },

  created() {
    this.$root.$on("translation-key-selected", this.onTranslationKeySelected);
  },

  beforeDestroy() {
    this.$root.$off("translation-key-selected", this.onTranslationKeySelected);
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),
    ...mapState("adminPanel", ["timer"]),

    bottomTextTranslationKey() {
      return this.bottomText || this.$t("admin_panel.select_translation_key");
    },
  },

  methods: {
    ...mapMutations("adminPanel", ["setTimerConfig"]),

    save() {
      this.setTimerConfig(this.getTimerConfig());
      this.$root.$emit("close-side-panel");
      this.$emit("navigate-back");
    },

    getTimerConfig() {
      const timerFields = LpConfigConstants.TIMER_FIELDS;
      return {
        [timerFields.HIDDEN]: this.hidden,
        [timerFields.DATE]: this.date,
        [timerFields.BOTTOM_TEXT]: this.bottomText,
        [timerFields.BOTTOM_TEXT_COLOR]: this.bottomTextColor,
        [timerFields.BOTTOM_TEXT_SIZE]: this.bottomTextSize,
      };
    },

    clearDateTime() {
      this.date = null;
    },

    editBottomText() {
      this.awaitingFieldForTranslationKey = LpConfigConstants.TIMER_FIELDS.BOTTOM_TEXT;
      this.$root.$emit("select-translation-key");
    },

    onTranslationKeySelected(translationKey) {
      if (this.awaitingFieldForTranslationKey === LpConfigConstants.TIMER_FIELDS.BOTTOM_TEXT) {
        this.bottomText = translationKey;
      }
      this.awaitingFieldForTranslationKey = null;
    },
  },

  watch: {
    timer: {
      immediate: true,
      handler: function (newValue) {
        const timerFields = LpConfigConstants.TIMER_FIELDS;

        this.hidden = newValue[timerFields.HIDDEN];
        this.date = newValue[timerFields.DATE];
        this.bottomText = newValue[timerFields.BOTTOM_TEXT];
        this.bottomTextColor = newValue[timerFields.BOTTOM_TEXT_COLOR];
        this.bottomTextSize = newValue[timerFields.BOTTOM_TEXT_SIZE];
      },
    },
  },
};
</script>

<style scoped></style>
