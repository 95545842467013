<template>
  <div class="is-relative">
    <slot></slot>
    <div :style="overlayStyle">
      <slot name="overlay"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionOverlayView",

  props: ["left", "right", "bottom", "top"],

  computed: {
    overlayStyle() {
      return {
        position: "absolute",
        left: this.left,
        right: this.right,
        bottom: this.bottom,
        top: this.top,
      };
    },
  },
};
</script>
