<template>
  <div id="inbox-messages-list" class="messages-container is-flex is-flex-direction-column">
    <div class="is-flex-grow-1"></div>
    <list-loading-indicator class="p-3 is-small" v-if="isLoading" :active="true"></list-loading-indicator>
    <message-list-item v-for="message in messages" :key="message.id" :message="message" :is-small="true"></message-list-item>
    <list-loading-indicator class="p-3 is-small" v-if="isSending" :active="true"></list-loading-indicator>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import MessageListItem from "@/web/components/inbox/InboxMessageListItem";
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "InboxMessages",

  components: { ListLoadingIndicator, MessageListItem },

  props: {
    threadId: {
      type: Number,
    },

    isSending: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      visibilityListener: null,
      isViewHidden: true,
    };
  },

  mounted() {
    this.loadMessagesFromNewThread();
    this.markThreadAsRead({ threadId: this.threadId });
    this.setInboxThreadVisible(this.threadId);
    this.addVisibilityListener();
  },

  destroyed() {
    this.removeVisibilityListener();
    this.setInboxThreadInvisible(this.threadId);
  },

  updated() {
    if (this.isInitialized && this.isActive) {
      this.markThreadAsRead({ threadId: this.threadId });
    }
  },

  watch: {
    threadId(value) {
      this.markThreadAsRead({ threadId: value });
      this.setInboxThreadVisible(this.threadId);
      this.loadMessagesFromNewThread();
    },

    isActive(newValue) {
      if (newValue) {
        this.markThreadAsRead({ threadId: this.threadId });
      }
    },
  },

  methods: {
    ...mapActions("inboxMessages", ["loadMessagesPage"]),
    ...mapActions("inboxThreads", ["markThreadAsRead"]),
    ...mapMutations("notifications", ["setInboxThreadVisible", "setInboxThreadInvisible"]),

    loadMessagesFromNewThread() {
      if (!this.isInitialized) {
        this.loadMessagesPage({ threadId: this.threadId });
      }
    },

    loadNextPage() {
      this.loadMessagesPage({ threadId: this.threadId });
    },

    addVisibilityListener() {
      if (!this.visibilityListener) {
        this.isViewHidden = document[this.visibilityHiddenValue];
        this.visibilityListener = e => {
          if (this.isViewHidden !== document[this.visibilityHiddenValue]) {
            this.isViewHidden = document[this.visibilityHiddenValue];
            if (this.isViewHidden) {
              this.onInvisible();
            } else {
              this.onVisible();
            }
          }
        };
        document.addEventListener(this.visibilityChangeEvent, this.visibilityListener);
      }
    },

    removeVisibilityListener() {
      if (this.visibilityListener) {
        document.removeEventListener(this.visibilityChangeEvent, this.visibilityListener);
      }
    },

    onVisible() {
      if (this.isActive) {
        this.markThreadAsRead({ threadId: this.threadId });
      }
      this.setInboxThreadVisible(this.threadId);
    },

    onInvisible() {
      this.setInboxThreadInvisible(this.threadId);
    },
  },

  computed: {
    ...mapGetters("inboxMessages", ["messagesFromThread", "getIsLoading", "getIsMore", "getLastMessageTimestamp"]),

    messages() {
      return this.messagesFromThread(this.threadId);
    },

    isMore() {
      return this.getIsMore(this.threadId);
    },

    isInitialized() {
      return !!this.getLastMessageTimestamp(this.threadId);
    },

    isLoading() {
      return this.getIsLoading(this.threadId);
    },

    visibilityHiddenValue() {
      if (typeof document.msHidden !== "undefined") {
        return "msHidden";
      } else {
        return "hidden";
      }
    },

    visibilityChangeEvent() {
      if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange";
      } else {
        return "visibilitychange";
      }
    },
  },
};
</script>

<style scoped>
.messages-container {
  padding-top: 14px;
}
</style>
