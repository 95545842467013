<template>
  <div class="p-1 is-relative">
    <template v-if="channels.length">
      <feed-wall-channel
        v-for="channel in channels"
        :key="channel.id"
        :channel="channel"
        :is-small="isSmall"
        @open-channel="$emit('open-channel', $event)"
      ></feed-wall-channel>
    </template>
    <b-loading v-else-if="isLoading" :is-full-page="false" :active="true" style="height: 320px"></b-loading>
    <div v-else class="placeholder-container has-text-secondary">
      {{ $t("feedwall.feedwall_empty_channels") }}
    </div>
  </div>
</template>

<script>
import FeedWallChannel from "@/web/components/feedwall/FeedWallChannel";

export default {
  name: "FeedWallChannels",

  components: { FeedWallChannel },

  props: {
    channels: {
      type: Array,
      required: true,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.placeholder-container {
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}
</style>
