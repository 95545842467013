<template>
  <div>
    <!--MODULE SPECIFIC FIELDS-->
    <div class="mt-1">
      <!--PAGE SIZE-->
      <div class="field">
        <label class="label has-text-primary">{{ $t("admin_panel.speakers_page_size") }}</label>
        <div class="control">
          <input
            class="input is-simple"
            type="number"
            min="0"
            step="1"
            :placeholder="$t('admin_panel.speakers_page_size')"
            v-model.number="$data[moduleFields.PAGE_SIZE]"
          />
        </div>
      </div>

      <!--SHOW COUNTRY-->
      <b-field class="mt-1">
        <b-switch v-model="$data[moduleFields.SHOW_COUNTRY]">{{ $t("admin_panel.speakers_show_country") }}</b-switch>
      </b-field>
    </div>
  </div>
</template>

<script>
import LpConfigConstants from "@/shared/constants/lp-config-constants";

export default {
  name: "AdminModuleConfigSpeakers",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    const moduleFields = LpConfigConstants.SPEAKERS_MODULE_FIELDS;
    return {
      [moduleFields.PAGE_SIZE]: this.value[moduleFields.PAGE_SIZE],
      [moduleFields.SHOW_COUNTRY]: this.value[moduleFields.SHOW_COUNTRY],
    };
  },

  computed: {
    moduleFields: () => LpConfigConstants.SPEAKERS_MODULE_FIELDS,

    specificFieldsObject() {
      return Object.values(this.moduleFields).reduce((acc, fieldKey) => {
        return { ...acc, [fieldKey]: this.$data[fieldKey] };
      }, {});
    },
  },

  watch: {
    specificFieldsObject: {
      immediate: false,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
