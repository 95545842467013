<template>
  <div>
    <div class="box">
      <template v-if="sortedTickets.length">
        <div v-for="ticket in sortedTickets" :key="ticket.id">
          <div class="columns is-mobile is-vcentered is-mobile">
            <div class="column is-narrow">
              <div class="is-flex is-flex-direction-column is-justify-content-flex-end">
                <div
                  class="button is-primary module-order-small-icon-button"
                  @click="moveUp(ticket)"
                  v-if="value.includes(ticket.id) && value.indexOf(ticket.id) > 0"
                >
                  <arrow-up class="svg-icon" style="fill: white"></arrow-up>
                </div>
                <div
                  class="button is-primary module-order-small-icon-button mt-1"
                  @click="moveDown(ticket)"
                  v-if="value.includes(ticket.id) && value.indexOf(ticket.id) < value.length - 1"
                >
                  <arrow-down class="svg-icon" style="fill: white"></arrow-down>
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <checkbox :value="value.includes(ticket.id)" @input="toggleSelection($event, ticket.id)"></checkbox>
            </div>
            <div class="column">
              <div>{{ ticket.name }}</div>
            </div>
          </div>
        </div>
      </template>
      <div class="has-text-centered" v-else>
        {{ $t("admin_panel.event_tickets_empty_error") }}
        <b-button type="is-primary" class="has-fullwidth mt-1" @click="openMainAdministrationPanel">
          {{ $t("admin_panel.redirect") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Checkbox from "@/shared/components/form/Checkbox";
import ArrowDown from "@/assets/arrow-down.svg";
import ArrowUp from "@/assets/arrow-up.svg";

export default {
  name: "EventTicketIdsPicker",
  components: { Checkbox, ArrowDown, ArrowUp },

  props: {
    value: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters("eventTickets", ["getAllTickets"]),

    sortedTickets() {
      return [...this.getAllTickets].sort((a, b) => {
        const aIndex = this.value.findIndex((value, index) => {
          return value === a.id;
        });
        const bIndex = this.value.findIndex((value, index) => {
          return value === b.id;
        });
        return aIndex - bIndex;
      });
    },
  },

  methods: {
    toggleSelection(isSelected, ticketId) {
      if (isSelected) {
        this.$emit("input", [...this.value, ticketId]);
      } else {
        this.$emit("input", [...this.value.filter(selectedId => selectedId !== ticketId)]);
      }
    },

    openMainAdministrationPanel() {
      window.open("https://panel.meetingapplication.com", "_blank");
    },

    moveUp(ticket) {
      const newArray = [...this.value];
      const ticketIndex = newArray.findIndex(value => value === ticket.id);
      [newArray[ticketIndex], newArray[ticketIndex - 1]] = [newArray[ticketIndex - 1], newArray[ticketIndex]];
      this.$emit("input", newArray);
    },

    moveDown(ticket) {
      const newArray = [...this.value];
      const ticketIndex = newArray.findIndex(value => value === ticket.id);
      [newArray[ticketIndex], newArray[ticketIndex + 1]] = [newArray[ticketIndex + 1], newArray[ticketIndex]];
      this.$emit("input", newArray);
    },
  },
};
</script>

<style scoped></style>
