<template>
  <div class="modal-card">
    <b-loading v-if="isRequesting" :is-full-page="false" :active="isRequesting"></b-loading>
    <header class="modal-card-head fw-thread-list-item px-3">
      <div class="columns is-mobile is-gapless mb-0 mt-3 is-vcentered has-fullwidth">
        <div class="column is-narrow">
          <profile-picture :picture="avatar" :initials="avatarInitials" class="fw-thread-avatar"></profile-picture>
        </div>
        <div class="column">
          <div class="fw-thread-title-container">
            <div class="fw-thread-title">
              {{ title }}
            </div>
            <div class="fw-thread-subtitle">
              {{ subtitle }}
            </div>
          </div>
        </div>
        <div class="column is-narrow is-flex">
          <div class="fw-thread-date">
            {{ $t("time.time_just_now_uppercase") }}
          </div>
        </div>
      </div>
      <button class="delete" type="button" @click="closeModal"></button>
    </header>
    <section class="modal-card-body modal-card-foot pt-2">
      <div class="control mb-2 has-fullwidth">
        <textarea class="textarea" v-model="message"></textarea>
        <template v-if="submitted">
          <p v-if="!$v.message.required" class="help is-danger has-fullwidth mb-1">{{ $t("common.field_required_error") }}</p>
        </template>
      </div>

      <div class="field">
        <b-switch size="anonymous-switch is-small" v-model="anonymous">
          {{ $t("session_questions.add_question_send_anonymously") }}
        </b-switch>
      </div>

      <div class="post-thread-buttons-container">
        <div class="button is-rounded is-danger" @click="closeModal">
          {{ $t("common.cancel") }}
        </div>
        <div class="button is-rounded is-primary" @click="tryToPost">
          {{ $t("common.send") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import RequestMixin from "@/shared/mixins/RequestMixin";
import Constants from "@/web/constants";
import { pluckErrorCode } from "@/shared/utils";

export default {
  name: "AgendaSessionCreateQuestionModal",

  components: { ProfilePicture },

  mixins: [RequestMixin],

  props: {
    agendaSessionId: {
      type: Number,
      required: true,
    },

    componentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      message: "",
      submitted: false,
      anonymous: false,
    };
  },

  validations() {
    return {
      message: { required },
    };
  },

  computed: {
    ...mapGetters("currentUser", ["fullName", "positionAndCompany", "initials", "picture"]),

    title() {
      if (this.anonymous) {
        return this.$t("session_questions.session_question_anonymous_post_title");
      } else {
        return this.fullName;
      }
    },

    subtitle() {
      if (this.anonymous) {
        return "";
      } else {
        return this.positionAndCompany;
      }
    },

    avatarInitials() {
      if (this.anonymous) {
        return "";
      } else {
        return this.initials;
      }
    },

    avatar() {
      if (this.anonymous) {
        return null;
      } else {
        return this.picture;
      }
    },

    errorMessage() {
      return this.error && this.$options.filters.pluckError(this.error);
    },
  },

  methods: {
    ...mapActions("agendaSessionQuestions", ["postQuestion"]),

    tryToPost() {
      if (!this.isRequesting) {
        this.$v.$touch();
        this.$data.submitted = true;

        if (!this.$v.$invalid) {
          this.send();
        }
      }
    },

    closeModal() {
      this.$parent.close();
    },
  },

  requests: {
    async send() {
      await this.postQuestion({
        componentId: this.componentId,
        agendaSessionId: this.agendaSessionId,
        message: this.message,
        anonymous: this.anonymous,
      }).then(result => this.closeModal());
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        const errorCode = pluckErrorCode(newVal);
        if (errorCode === Constants.AGENDA_QUESTION_WAITING_FOR_MODERATION_ERROR_CODE) {
          const title = this.$t("session_questions.session_question_moderated_info_title");
          const message = this.$t("session_questions.session_question_moderated_info_subtitle");
          this.$root.openInfoModal({ title, message });
          this.closeModal();
        } else {
          this.$root.openErrorModal(this.error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fw-thread-list-item {
  background: var(--side-menu-background-color);
}

.thread-picture-container {
  height: 56px;
  width: 100%;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  &:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--primary-color);
    opacity: 0.1;
  }
}

.thread-picture-add-icon {
  width: 22px;
  height: 22px;
}

.thread-picture-add-title {
  font-size: 18px;
  line-height: 130%;
}

.thread-picture-add-subtitle {
  font-size: 14px;
  line-height: 130%;
}

.post-thread-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.anonymous-switch {
  color: var(--text-color-secondary);
}
</style>
